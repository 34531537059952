import { template as template_751c44053e9c427a84b887b0ea537538 } from "@ember/template-compiler";
const WelcomeHeader = template_751c44053e9c427a84b887b0ea537538(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
