import { template as template_566b288f4fe54e48844d9dd63ec267d8 } from "@ember/template-compiler";
const FKLabel = template_566b288f4fe54e48844d9dd63ec267d8(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
